import * as React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import Layout from "../components/layout";
import Seo from "../components/seo";

const DashboardPageTemplate = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const title = data?.prismicDashboardPage?.data?.title?.text || "Dashboard";
  const iframeSrc = data?.prismicDashboardPage?.data?.dashboard_src?.url || "";
  const content = data?.prismicDashboardPage?.data?.content?.html || "";

  return (
    <Layout location={location} title={siteTitle} slug="dashboard">
      <div className="container content">
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <div className="container">
        <div className="iframe-wrapper">
          <iframe
            id="dashboard"
            width="100%"
            height="100%"
            src={iframeSrc}
            title={siteTitle}
            allowFullScreen
          ></iframe>
        </div>
      </div>
      {/* <div className="charts container">
        <div className="chart">
          <h2>Tech Updates</h2>
          <BubbleChart />
        </div>
      </div> */}
    </Layout>
  );
};

export const Head = ({ data }) => {
  const title = "Ultimate F1 dashboard";
  const description = "Dive into F1 2024 season data: drivers' and constructors' rankings, predictions, pit stops, tech updates, crash costs, used elements and much more.";

  return <Seo title={title} description={description} />;
};

export const dashboardPageQuery = graphql`
  query DashboardPageQuery {
    prismicDashboardPage {
      _previewable
      data {
        dashboard_src {
          url
        }
        title {
          text
        }
        content {
          html
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default withPrismicPreview(DashboardPageTemplate);