import React from 'react';
import PropTypes from 'prop-types';

const Burger = ({ open, setOpen, ariaLabel, ...props }) => {
  const isExpanded = open ? true : false;

  return (
    <div
      tabIndex="0"
      role="button"
      className="burger burger-squeeze display-on-tablet"
      aria-label={ariaLabel}
      aria-expanded={isExpanded}
      onClick={() => setOpen(!open)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          setOpen(!open);
        }
      }}
      {...props}
    >
      <div className="burger-lines"></div>
    </div>
  );
}

Burger.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
};

export default Burger;