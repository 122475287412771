import React from "react";
import { Link, graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Countdown from "../components/countdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import banner from "../images/banner.png";

const HomePageTemplate = ({ data, location }) => {
  const siteTitle = data?.prismicHomePage?.data.site_title.text;
  const bgImages = data?.prismicHomePage?.data.bg_images || [];
  const randomBgImage = bgImages.length > 0 ? getImage(bgImages[Math.floor(Math.random() * bgImages.length)].bg_image) : null;
  const heroTitle = data?.prismicHomePage?.data.hero_title.html || "";
  const heroDescriptionPart1 = data?.prismicHomePage?.data.hero_description_part_1 || "";
  const heroDescriptionPart2 = data?.prismicHomePage?.data.hero_description_part_2 || "";
  const heroCta = data?.prismicHomePage?.data.hero_cta || "";
  const targetDate = data?.prismicHomePage?.data.next_race_date;
  const nextGp = data?.prismicHomePage?.data.next_gp;
  const now = new Date();
  const blogList = data?.allPrismicPost?.nodes || [];

  return (
    <Layout location={location} title={siteTitle} slug="home">
      <section className="hero">
        <div className="container">
          <h2 dangerouslySetInnerHTML={{ __html: heroTitle }} />
          <p>{heroDescriptionPart1} <br className="display-none-on-mobile" />{heroDescriptionPart2}</p>
          <Link to="/dashboard" className="btn">{heroCta} <span>→</span></Link>
        </div>
        {randomBgImage && (
          <GatsbyImage
            image={randomBgImage}
            alt={randomBgImage.alt || "Background Image"}
            className="hero-bg"
          />
        )}
      </section>
      {new Date(targetDate) > now && (
        <section className="container">
          <Countdown gp={nextGp} targetDate={targetDate} />
        </section>
      )}
      <section className="latest-posts container">
        <div className="flex">
          <h2>Latest Posts</h2>
          <Link to="/posts" className="see-more" title="All posts">All <span>→</span></Link>
        </div>
        <ul className="cards">
          {blogList.map((post) => (
            <li key={post.uid} className="card">
              <article className="post-list-item" itemScope itemType="http://schema.org/Article">
                <Link to={post.url} itemProp="url">
                  <div className="zoom-image">
                    <GatsbyImage
                      image={getImage(post.data.cover)}
                      alt={post.data.cover.alt || ""}
                    />
                  </div>
                  <div className="caption">
                    <h3 itemProp="headline">{post.data.title.text}</h3>
                  </div>
                  <small>{post.data.post_date}</small>
                </Link>
              </article>
            </li>
          ))}
        </ul>
      </section>
    </Layout>
  );
};

export const Head = ({ data }) => {
  const title = "Formula 1 Dashboard | Race data and stats all in one place";
  const description = "Go beyond the finish line with an interactive dashboard of the 2024 F1 season, featuring race stats, rankings, graphs and analysis.";

  return <Seo title={title} description={description} isHome={true} image={banner} />;
};

export const homePageQuery = graphql`
  query HomePageQuery {
    prismicHomePage {
      _previewable
      data {
        site_title {
          text
        }
        bg_images {
          bg_image {
            gatsbyImageData(width: 2160, height: 1440, placeholder: BLURRED)
            alt
          }
        }
        hero_title {
          html
        }
        hero_description_part_1
        hero_description_part_2
        hero_cta
        next_race_date
        next_gp
      }
    }
    allPrismicPost(
      limit: 3
      sort: { data: { post_date: DESC }}
    ) {
      nodes {
        url
        uid
        data {
          post_date
          title {
            text
          }
          cover {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(HomePageTemplate)