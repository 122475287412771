import React, { useEffect, useState } from 'react';

const Countdown = ({ gp, targetDate }) => {
  const [time, setTime] = useState({
    days: '--',
    hours: '--',
    minutes: '--',
    seconds: '--',
  });

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      const target = new Date(targetDate);
      const diff = Math.max(target - now, 0);

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((diff / (1000 * 60)) % 60);
      const seconds = Math.floor((diff / 1000) % 60);

      setTime({
        days: String(days).padStart(2, '0'),
        hours: String(hours).padStart(2, '0'),
        minutes: String(minutes).padStart(2, '0'),
        seconds: String(seconds).padStart(2, '0'),
      });
    };

    const timerId = setInterval(updateCountdown, 1000);

    return () => clearInterval(timerId);
  }, [targetDate]);

  return (
    <div className="countdown-timer-container">
      <div className="session">
        <h3>{gp}</h3>
        <strong>Grand Prix</strong>
      </div>
      <ul>
        <li>{time.days}<span>days</span></li>
        <li>{time.hours}<span>hrs</span></li>
        <li>{time.minutes}<span>mins</span></li>
        <li>{time.seconds}<span>sec</span></li>
      </ul>
    </div>
  );
};

export default Countdown;