// src/utils/useIntersectionObserver.js
import { useEffect } from 'react';

const useIntersectionObserver = () => {
  useEffect(() => {
    const cards = document.querySelectorAll('.card');

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    cards.forEach((card, index) => {
      card.style.setProperty('--animation-delay', `${index * 0.1}s`);
      observer.observe(card);
    });
  }, []);
};

export default useIntersectionObserver;
