import { linkResolver } from "./link-resolver";
import PostTemplate from "../../src/templates/blog-post";
import HomePageTemplate from "../../src/templates/home-page";
import DashboardPageTemplate from "../../src/templates/dashboard";
import AboutPageTemplate from "../../src/templates/about";

// Define a map of document types to components
const componentResolverMap = {
  homePage: HomePageTemplate,
  post: PostTemplate,
  dashboardPage: DashboardPageTemplate,
  aboutPage: AboutPageTemplate,
};

// Create a function to resolve components based on document type
const componentResolverFromMap = (doc) => {
  if (Array.isArray(doc)) {
    console.log('Received an array:', doc);
    if (doc.length === 0) {
      console.error('Document array is empty');
      return null;
    }
    doc = doc[0]; // Assume we want the first document in the array
  } else {
    console.log('Document:', doc);
  }

  if (!doc || !doc.type) {
    console.error('Document is undefined or type is missing:', doc);
    return null;
  }

  console.log('Document type:', doc.type);
  console.log('Full document:', JSON.stringify(doc, null, 2));

  // Use the map to get the component based on document type
  const Component = componentResolverMap[doc.type];

  if (!Component) {
    console.error('Unknown document type:', doc.type);
    return null;
  }

  return Component;
};

export const repositoryConfigs = [
  {
    repositoryName: 'formula-1-dashboard',
    linkResolver,
    componentResolver: componentResolverFromMap,
  },
];