import * as React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import Layout from "../components/layout";
import Seo from "../components/seo";

const AboutPageTemplate = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle} slug="about">
      <section className="container">
        <h1>{data.prismicAboutPage.data.title.text}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: data.prismicAboutPage.data.content.html || [],
          }}
        />
      </section>
    </Layout>
  );
};

export const Head = ({ data }) => {
  const { title } = data?.prismicAboutPage?.data || {};
  const description = "Driven by a 30-year passion for Formula One and a love for data visualization, Formula1Dashboard was created to offer fans comprehensive insights and visualizations. Experience F1 through accurate, unbiased data, and join a journey where every race tells a story."
  return <Seo title={title.text} description={description} />;
};


export const aboutPageQuery = graphql`
  query AboutPageQuery {
    prismicAboutPage {
      _previewable
      data {
        title {
          text
        }
        content {
          html
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default withPrismicPreview(AboutPageTemplate);